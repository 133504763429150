/* roboto-regular */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Roboto-Regular.ttf');
}

/* roboto-bold */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/Roboto-Bold.ttf');
}

/* roboto-mono-regular */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/RobotoMono-Regular.ttf');
}

$paterva-red: #6c1720;
$maltego-yellow: #FFB30F;
$maltego-yellow-hover: #FFB30F;
$red: #843737;
$yellow: #BAA561;
$green: #81A178;
$blue: #C1C3C7;
$white: #fff;

$brand-primary: #1d242a;
$secondary: #303849;

$brand-success: $green;
$brand-info: #93A2C3;
$brand-warning: $yellow;
$brand-danger: $red;

$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;
$body-bg: #F7F7F7;
$input-placeholder-color: #ccc;
$input-border-color:  rgba(0,0,0,.2);

$almost-white: #f7f7f9;
$font-family-base: Roboto, Arial, sans-serif;
$font-family-sans-serif: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;
$font-family-serif:      Roboto, Georgia, Cambria, "Times New Roman", Times, serif;
$font-family-monospace:  'Roboto Mono', Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$code-color: $red;

@import './components/alert.scss';
@import './components/hr.scss';
@import './components/checkbox.scss';

@import '~bootswatch/dist/litera/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/litera/bootswatch';
@import '../node_modules/bootstrap-select/dist/css/bootstrap-select.css';

body > .container, body > .container-fluid {
  padding-top: 20px;
  /* to push the footer down but do not show scrollbar if not needed */
  min-height: calc(100vh - 147px - 82px); // 147: footer height, 82: navbar height
}

.refresh-manage {
  padding: 10px 0;
}

.refresh-manage a {
  min-width: 65px;
}

.bootstrap-select {
  width: 100% !important;
}

.dropdown-item:hover, .dropdown-item:focus {
  outline: none;
}

td {
  vertical-align: middle !important;
}

.table td, .table th {
  border-top: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.breadcrumb {
  background-color: $secondary;
  color: $almost-white;
  font-size: 16px;
}

.breadcrumb a {
  color: $almost-white;
}

.footer-container {
  padding: 30px 20px;
}

.footer-container p {
  font-size:14px;
  margin: 0;
}

footer {
  color: $brand-primary !important;
}

.form-control {
  font-size: 15px;
  border-width: 0.15em;
}

.form-control:focus {
  border-color: $blue;
  box-shadow: 0 0;
}

a {
  text-decoration: none !important;

  &.card {
    color: $secondary;
  }
}

.link {
  color: $maltego-yellow-hover;

  &:hover {
    color: $maltego-yellow;
  }
}

.bg-dark {
  background-color: $brand-primary !important;
}

.table .thead-dark th {
  background-color: $brand-primary;
  border-color: $brand-primary;
}

.alert-success {
  background-color: $brand-success;
  border-color: $brand-success;
}

.alert-warning {
  background-color: $brand-warning;
  border-color: $brand-warning;
}

.alert-danger {
  background-color: $brand-danger;
  border-color: $brand-danger;
}

.alert ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.alert {
  color: $white;
  font-size: 16px;
}

.btn-full {
  width: 100%;
}

.form-inline {
  margin-bottom: 10px;

  select {
    margin: 0 20px;
  }
}

.col-lg-2-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
}

.card-block {
  word-wrap: break-word;
}

.btn-wide {
  min-width: 164px;
}

.invalid-feedback {
  display: block;
}

.card {
  margin-bottom: 40px;
}

.homepage-card {
  background-color: $white;
  transition: all 150ms ease;
  min-height: 170px;

  &:hover {
    border-color: transparent;
    background-color: rgb(68, 75, 90, 0.1);
  }
}

p {
  font-size: 15px;
  font-family: $font-family-base;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #f7f7f9;
  text-decoration: none;
  opacity: 0.75;
  outline: 0 !important;
}

.close {
  color: #f7f7f9;
  opacity: 1;
}

#delete-selected {
  margin-left: 10px;
  white-space: nowrap;
}

.page-description {
  position: relative;
  padding-right: 120px;

  .buttons-wrapper {
    position: absolute;
    top: 0;
    right: 0;

    form {
      display: inline-block;
    }
  }
}

.btn {
  font-weight: 500;
  text-transform: uppercase;
  border-radius: $border-radius;
}

.btn-primary {
  color: $white;
}

.btn-success {
  color: $brand-primary;
  background-color: $maltego-yellow;
  border-color: $maltego-yellow;
  text-transform: uppercase;

  &:hover, &:not(:disabled):not(.disabled):active {
    color: $brand-primary;
    background-color: $maltego-yellow-hover;
    border-color: $maltego-yellow-hover;
  }

  &:focus, &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(206,154,17,.5);
  }
}

.btn-light {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;

  &:hover, &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: $brand-primary;
    border-color: $brand-primary;
  }

  &:focus, &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(52,58,64,.5);
  }
}

.nav-item .dropdown-menu.show {
  background-color: $secondary;

  .dropdown-item {
    color: $white;

    &:hover {
      color: $maltego-yellow-hover;
      background-color: rgba(247,247,247,.1);
    }
  }
}

.navbar {
    &.bg-dark {
	background-color: $brand-primary !important;
    }
}

.navbar-dark .navbar-nav {
  .nav-link {
    color: $white;
    font-weight: 600;
    border-bottom: 2px solid transparent;

    &:hover {
      color: $maltego-yellow-hover;
      border-bottom-color: $maltego-yellow-hover;
    }
  }
}

.navbar-dark .navbar-brand {
  &:hover {
    color: $maltego-yellow-hover;
    border-bottom-color: $maltego-yellow-hover;
  }
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  line-height: 22px;
}
