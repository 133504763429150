hr.hr-md{
    width: 60% ;
}

hr.hr-sm{
    width: 40% ;
}

hr.hr-xs{
    width: 20% ;
}

hr.hr-primary{
    border-color: $brand-primary ;
}

hr.hr-paterva{
    border-color: #6c1821;
}
