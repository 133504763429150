// Litera 5.2.2
// Bootswatch


// Variables

:root {
  color-scheme: light;
}

// Navbar

.navbar {
  font-size: $font-size-sm;

  &.bg-dark {
    background-color: $success !important;
  }

  &.bg-light {
    background-color: $white !important;
    border: 1px solid rgba(0, 0, 0, .1);

    &.navbar-fixed-top {
      border-width: 0 0 1px;
    }

    &.navbar-fixed-bottom {
      border-width: 1px 0 0;
    }
  }
}

// Typography

p {
  font-family: $font-family-serif;
}

blockquote {
  font-style: italic;
}

footer {
  font-size: $font-size-sm;
}

.lead {
  font-family: $font-family-sans-serif;
  color: $gray-600;
}

// Tables

table,
.table {
  font-size: $font-size-sm;
}

// Navs

.nav,
.breadcrumb,
.pagination {
  font-size: $font-size-sm;
}

.dropdown-menu {
  font-size: $font-size-sm;
}

// Indicators

.alert {
  font-size: $font-size-sm;
  color: $white;

  &,
  p {
    font-family: $font-family-sans-serif;
  }

  a,
  .alert-link {
    font-weight: 400;
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value linear-gradient($value, mix($body-bg, $value, 15%)) repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }

  &-light {
    &,
    a,
    .alert-link {
      color: $body-color;
    }
  }
}

.badge {
  &.bg-light {
    color: $dark;
  }
}

// Containers

.list-group {
  font-size: $font-size-sm;
}
