.alert{
    z-index:50 ;
}

.message-container{
    position: fixed;
    bottom: 2% ;
    left: 2% ;
    max-width: 96% ;
    z-index:4;
}
